<template>
  <b-container v-can="'purchase.request.view'">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado De Solicitudes de Compra</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$refs.createOrUpdate.showModal()"
              v-can="'purchase.request.create'"
              >Agregar Nueva</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="3" class="ml-auto">
                <!-- INPUT SEARCH -->
                <div class="form-group">
                  <b-form-group>
                    <b-input-group>
                      <b-form-input
                        :disabled="
                          !Array.isArray(getPurchase) ||
                          (getPurchase.length === 0 && isBusy === false)
                        "
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Buscar..."
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getPurchase"
                  :fields="columns"
                  :filter="filter"
                  @filtered="onFiltered"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :busy="
                    !Array.isArray(getPurchase) || getPurchase.length === 0
                      ? true
                      : false || isBusy == true
                  "
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(area)="data">
                    {{ data.item.area.name }}
                  </template>
                  <template v-slot:cell(user_applicant)="data">
                    {{ data.item.user_applicant.fullname }}
                  </template>
                  <template v-slot:cell(option)="data">
                    <b-dropdown class="m-2" text="Primary" variant="primary" size="sm" >
                      <template #button-content> </template>
                      <!-- VER -->
                      <b-dropdown-item
                        v-can="'purchase.request.view'"
                        :to="{
                          name: 'purchase_requests.show',
                          params: { id: data.item.id },
                        }"
                      >
                        <i class="ri-eye-line mr-2 h5 text-primary"></i> Ver
                        detalles
                      </b-dropdown-item>
                        <!-- COMENTARIO -->
                      <b-dropdown-item
                        v-can="'purchase.request.view'"
                        @click="$refs.comentaries.show('sc', data.item.id)"
                      >
                        <i class="ri-chat-smile-3-line mr-2 h5 text-info"></i
                        >Comentario
                      </b-dropdown-item>
                      <!-- CONVERTIR EN SOLICITUD DE COMPRA -->
                      <b-dropdown-item
                       v-can="'purchase.request.approved'"
                        v-if="data.item.status === 6"
                         @click="$refs.convert.showModal(data.item)"
                      >
                        <i class="ri-git-merge-line mr-2 h5 text-dark"></i
                        >Convertir en orden de Compra
                      </b-dropdown-item>
                       <!-- ENVIAR PARA APROBAR SOLICITUD DE COMPRA -->
                      <b-dropdown-item
                        v-can="'purchase.request.approved'"
                        v-if="data.item.status === 1"
                        @click="approved({id: data.item.id, type: 1, status: 6})"
                      >
                        <i class="ri-mail-send-line mr-2 h5 text-success"></i>Enviar para aprobación
                      </b-dropdown-item>
                      <!-- RECHAZAR SOLICITUD -->
                      <b-dropdown-item
                        v-can="'purchase.request.rejected'"
                        v-if="data.item.status === 6"
                        @click="changeStatus(data.item.id)"
                      >
                        <i class="ri-close-circle-line mr-2 h5 text-danger"></i
                        >Rechazar Solicitud de Compra
                      </b-dropdown-item>
                      <!-- ELIMINAR -->
                      <b-dropdown-item
                        v-can="'purchase.request.delete'"
                        v-if="data.item.status === 1"
                        @click="showMsgBoxTwo(data.item.id)"
                      >
                        <i class="ri-delete-bin-line mr-2 h5 text-dark"></i
                        >Eliminar
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <b-pagination
                  v-if="totalRows > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modals ref="createOrUpdate" />
    <Comentaries ref="comentaries" />
    <convert ref="convert" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import Comentaries from "@/components/core/modals/Comentaries.vue";
import modals from "@/components/core/modals/PurchaseModals.vue";
import convert from "@/components/core/modals/ConvertPurchaseToOrders.vue"

export default {
  name: "List",
  components: {
    Comentaries,
    modals,
    convert
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.isBusy = true;
    core.index();
    await this.listArea();
    await this.listPayment();
    await this.listPurchaseRequests();
    this.totalRows = this.getPurchase.length;
    this.isBusy = false;
  },

  data() {
    return {
      columns: [
        { label: "N°", key: "correlative", class: "text-center" },
        { label: "Fecha", key: "created_at", class: "text-center" },
        { label: "Área", key: "area", class: "text-center" },
        { label: "Estado", key: "status_description", class: "text-center" },
        { label: "Solicitante", key: "user_applicant", class: "text-center" },
        { label: "Descripción", key: "description", class: "text-center" },
        { label: "Opciones", key: "option", class: "text-lefght" },
      ],
      perPage: 10,
      currentPage: 1,
      filter: null,
      totalRows: "",
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Solicitudes de Compras",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      listArea: "area/listArea",
      listPayment: "payments/listPayment",
      listPurchaseRequests: "purchase/listPurchaseRequests",
      destroyPurchaseRequests: "purchase/destroyPurchaseRequests",
      approved : "purchase/approvedPurchaseRequest"
    }),
    async remove(item) {
      try {
        await this.destroyPurchaseRequests(item);
        this.totalRows = this.getPurchase.length;
        core.showSnackbar("success", "Solicitud eliminada correctamente");
      } catch (error) {
        console.log(error);
      }
    },
    async changeStatus(payload) {
      try {
        this.isBusy = true;
        const res = await this.$store.dispatch(
          "purchase/changeStatusPurchaseRequest",
          { id: payload, status: 5 }
        );
        core.showSnackbar("success", res.message);
        this.$router.replace(`/purchase_requests/show/${payload}`);
        this.isBusy = false;
      } catch (error) {
        console.log(error);
        this.isBusy = false;
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters({
      getPurchase: "purchase/getPurchaseRequests",
    }),
  },
};
</script>
<style>
</style>
