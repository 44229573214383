var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"bv-modal-purchase","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Agregar solicitud de compra")]},proxy:true}])},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label-for":"Área","label":"Área"}},[_c('ValidationProvider',{attrs:{"name":"Área","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Seleccionar...","options":_vm.getAreaList,"label":"name","reduce":function (name) { return name.id; },"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v("Agregar área")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v(" Agregar área ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.area_id),callback:function ($$v) {_vm.$set(_vm.form, "area_id", $$v)},expression:"form.area_id"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Detalles de solicitud de compra","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"Detalles de solicitud de compra","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Ingrese una descripción","rows":"1","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('hr'),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Tipo de pago cotización (Opcional)","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"Tipo de pago","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"type","reduce":function (type) { return type.id; },"placeholder":"Seleccionar...","options":_vm.getPayment,"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v("Agregar tipo de pago")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.type)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v(" Agregar tipo de pago ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.payment_type),callback:function ($$v) {_vm.$set(_vm.form, "payment_type", $$v)},expression:"form.payment_type"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Soporte de cotización (Opcional)","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"Soporte de cotización","rules":"file:application/pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"p-2",attrs:{"accept":".pdf","browse-text":".pdf","placeholder":"Archivo de Cotización","drop-placeholder":"Drop file here..."},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}}),_c('small',{staticClass:"text-danger"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-button',{staticClass:"m-3 mt-4",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v("Enviar solicitud")])],1)],1)])]}}])})],1),_c('modals',{ref:"createOrUpdate"}),_c('modals-purchase',{ref:"create"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }