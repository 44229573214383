<template>
  <div>
    <b-modal id="bv-modal-purchase" hide-footer>
      <template #modal-title>Agregar solicitud de compra</template>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="new-user-info">
            <b-row>
              <!-- AREA -->
              <b-form-group class="col-md-12" label-for="Área" label="Área">
                <ValidationProvider
                  name="Área"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="form.area_id"
                    placeholder="Seleccionar..."
                    :options="getAreaList"
                    label="name"
                    :reduce="(name) => name.id"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                    :selectable="
                      (item) => (item.id === 1 ? item.disabled : item)
                    "
                  >
                    <template #no-options="{}">
                      Sin Resultados,<b-button
                        variant="link"
                        block
                        @click="$refs.createOrUpdate.showModal()"
                        >Agregar área</b-button
                      >
                    </template>
                    <template v-slot:option="option">
                      <slot>
                        {{ option.name }}
                        <button
                          v-if="option.id === 1 ? true : false"
                          class="btn btn-link btn-block"
                          @click="$refs.createOrUpdate.showModal()"
                        >
                          Agregar área
                        </button></slot
                      >
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                class="col-md-12"
                label="Detalles de solicitud de compra"
                label-for="description"
              >
                <ValidationProvider
                  name="Detalles de solicitud de compra"
                  rules="required|min:3"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    placeholder="Ingrese una descripción"
                    v-model="form.description"
                    rows="1"
                    max-rows="6"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <hr />
              <b-form-group
                class="col-md-12"
                label="Tipo de pago cotización (Opcional)"
                label-for="description"
              >
                <ValidationProvider
                  name="Tipo de pago"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    label="type"
                    :reduce="(type) => type.id"
                    v-model="form.payment_type"
                    placeholder="Seleccionar..."
                    :options="getPayment"
                    :selectable="
                      (item) => (item.id === 1 ? item.disabled : item)
                    "
                  >
                    <template #no-options="{}">
                      Sin Resultados,<b-button
                        variant="link"
                        @click="$refs.create.showModal()"
                        >Agregar tipo de pago</b-button
                      >
                    </template>
                    <template v-slot:option="option">
                      <slot>
                        {{ option.type }}
                        <button
                          v-if="option.id === 1 ? true : false"
                          class="btn btn-link btn-block"
                          @click="$refs.create.showModal()"
                        >
                          Agregar tipo de pago
                        </button></slot
                      >
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                class="col-md-12"
                label="Soporte de cotización (Opcional)"
                label-for="description"
              >
                <ValidationProvider
                  name="Soporte de cotización"
                  rules="file:application/pdf"
                  v-slot="{ errors }"
                >
                  <b-form-file
                    class="p-2"
                    v-model="form.file"
                    accept=".pdf"
                    browse-text=".pdf"
                    placeholder="Archivo de Cotización"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <small class="text-danger">
                    <span>{{ errors[0] }}</span>
                  </small>
                </ValidationProvider>
              </b-form-group>

              <b-button class="m-3 mt-4" variant="primary" block type="submit"
                >Enviar solicitud</b-button
              >
            </b-row>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <modals ref="createOrUpdate" />
    <modals-purchase ref="create" />
  </div>
</template>
 <script>
 import swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
import modals from "@/components/core/modals/AreaModals.vue";
import ModalsPurchase from "@/components/core/modals/PaymentsModals";
export default {
  created() {
    core.index();
  },
  components: {
    modals,
    ModalsPurchase,
  },
  data() {
    return {
      form: {
        id: null,
        area_id: null,
        payment_type: null,
        file: null,
        description: null,
      },
    };
  },
  methods: {
    ...mapActions({
      createOrUpdate: "purchase/storePurchaseRequest",
    }),
    showModal(item) {
      this.form = {
        id: null,
        area_id: null,
        payment_type: null,
        file: null,
        description: null,
      };
      this.$bvModal.show("bv-modal-purchase");
    },
    async onSubmit() {
      try {
        const res = await this.createOrUpdate(this.form);
        if(res) {
          swal.fire({
          title: `Atención`,
          text: 'Registro Guardado',
          icon: "success",
          showConfirmButton: false,
          timer: 2000
        });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$bvModal.hide("bv-modal-purchase");
      }
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getPayment: "payments/getPayment",
    }),
  },
};
</script>